<template>
  <app-data-table
    :title="labels.title"
    :columns="columns"
    :fields="fields"
    :entity="entity"
    :breadcrumbs="breadcrumbs"
    :customBody="false"
  >
    <template v-slot:columns="slotProps">
      <tbody>
        <tr v-for="item in slotProps.items" :key="item.id">
          <td>{{ item.full_name }}</td>
          <td>
            <v-chip :color="item.status > 1 ? 'black' : 'green'" dark> </v-chip>
          </td>
        </tr>
      </tbody>
    </template>
  </app-data-table>
</template>

<script>
export default {
  name: "Users",
  components: {},
  data: () => ({
    entity: "users",
    columns: [{ name: "full_name" }, { name: "status" }],
    fields: [
      { field: "name" },
      { field: "last_name" },
      { field: "full_name", append: true },
      { field: "status" },
    ],
  }),
  methods: {},
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"](this.entity);
      },
    },
    breadcrumbs: {
      get() {
        return [
          {
            text: this.labels.title,
            disabled: true,
            href: "breadcrumbs_dashboard",
          },
        ];
      },
    },
  },
};
</script>
