<template>
  <app-form-page
    :breadcrumbs="breadcrumbs"
    :title="labels.title"
    :entity="entity"
    :id="id"
    ref-name="roleForm"
  >
    <template v-slot:content>
      <v-form lazy-validation ref="roleForm">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :label="labels.name + '*'"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:subgrid>
      <app-subgrid-nn
        :id="id"
        :entity="entity"
        related-entity="permissions"
        :columns="columns"
      >
      </app-subgrid-nn>
    </template>
  </app-form-page>
</template>

<script>
export default {
  name: "RoleForm",
  components: {},
  props: ["id"],
  data: () => ({
    entity: "roles",
    columns: [{ name: "name" }],
  }),
  methods: {},
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"](this.entity);
      },
    },
    rules: {
      get() {
        return this.$store.getters["rules"];
      },
    },
    breadcrumbs: {
      get() {
        return [
          {
            text: this.labels.title,
            disabled: false,
            href: "/" + this.entity,
          },
          {
            text: this.name,
            disabled: true,
            href: "",
          },
        ];
      },
    },
    name: {
      get() {
        return this.$store.state.formPage.record.name;
      },
      set(value) {
        this.$store.commit("formPage/SET_RECORD_ELEMENT", {
          name: "name",
          value: value,
        });
      },
    },
  },
};
</script>

