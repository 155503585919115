<template>
  <div>
    <app-page :breadcrumbs="items" :title="labels.title">
      <template v-slot:toolbar>
        <v-toolbar dense flat color="grey" class="fixed-bar">
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:content> </template>
    </app-page>
  </div>
</template>

<script>
export default {
  name: "Configuration",
  components: {},
  data: () => ({
    items: [
      {
        text: "Configuration",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],
  }),
  methods: {},
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"]("configuration");
      },
    },
  },
};
</script>

<style scoped>
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 3em;
  z-index: 2;
}
</style>
