<template>
  <app-data-table
    :title="labels.title"
    :columns="columns"
    :fields="fields"
    :entity="entity"
    :breadcrumbs="breadcrumbs"
  >
  </app-data-table>
</template>

<script>
export default {
  name: "Permission",
  components: {},
  data: () => ({
    entity: "permissions",
    columns: [{ name: "name" }],
    fields: [{ field: "name" }],
  }),
  methods: {},
  computed: {
    labels: {
      get() {
        return this.$store.getters["labels"](this.entity);
      },
    },
    breadcrumbs: {
      get() {
        return [
          {
            text: this.labels.title,
            disabled: true,
            href: "breadcrumbs_dashboard",
          },
        ];
      },
    },
  },
};
</script>

